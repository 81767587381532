import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of as observableOf, Observable } from 'rxjs';

import { EnterpriseRegistration } from '../';
import { RegistrationStep } from '../registration-step';
import { StepName } from '../registration-step-name';
import { AdultDirectSignupStepComponent } from './adult-direct-signup-step.component';

@Injectable({
  providedIn: 'root',
})
export class AdultDirectSignupConfig extends RegistrationStep {
  readonly component = AdultDirectSignupStepComponent;
  readonly progress = 90;
  readonly form: FormGroup;

  initComponent(_component: AdultDirectSignupStepComponent, _state: EnterpriseRegistration): void {}

  submit(state: EnterpriseRegistration): Observable<boolean> {
    state.setCurrentStep(StepName.directSignup);
    return observableOf(true);
  }
}
