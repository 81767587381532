<label [for]="inputId" *ngIf="inputLabel" [ngClass]="{ disabled: isDisabled }">{{ inputLabel }}</label>
<input
  [id]="inputId"
  type="text"
  [ngClass]="{ disabled: isDisabled }"
  [class.is-invalid]="errorMessage"
  aria-label="Date Input"
  inputmode="decimal"
  class="form-control"
  [placeholder]="placeholder"
  (input)="maskDate($event.target)"
  (focusout)="validateDate($event.target)"
  [(ngModel)]="_inputText"
  (ngModelChange)="handleChange($event)"
  (blur)="handleTouched()"
/>
<div class="mt-1 text-danger" *ngIf="errorMessage">
  <img src="/assets/images/icons/icon-warning.svg" />
  <span class="small font-weight-light pl-1">{{ errorMessage }}</span>
</div>
<div
  class="mt-2 small text-emphasis-medium font-weight-light"
  [ngClass]="{ disabled: isDisabled }"
  *ngIf="helperText && !errorMessage"
>
  {{ helperText }}
</div>
