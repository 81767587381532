<div class="modal-header p-0 text-center top-rounded">
  <div class="bg-dark border-0 d-flex download-app-image-container top-rounded">
    <img
      class="img-fluid download-app-image top-rounded"
      src="/assets/images/download-app.jpg"
      alt="Download App Hero Image"
    />
  </div>

  <omgui-close-x
    type="button"
    class="btn-close position-absolute p-3"
    height="32"
    width="32"
    stroke="white"
    backgroundOpacity="0.3"
    backgroundFill="#1A1A1A"
    fill="none"
    (click)="modal.dismiss()"
  ></omgui-close-x>
</div>

<div class="my-3 modal-body text-center">
  <div class="mx-4 mb-4">
    <h3 class="h4 px-4" id="download-app">Connect to on-demand care from our mobile apps</h3>
    <p>
      Get access to 24/7 virtual care over video or messaging, anywhere you are.
      <a class="app-modal font-weight-bold" href="https://www.onemedical.com/virtual-care/">Learn More</a>
    </p>
  </div>

  <div class="d-block d-sm-none rounded-bottom-0">
    <omgui-button
      [variant]="ButtonVariant.primary"
      [internalLink]="linksService.appStoreRedirect"
      aria-label="download mobile app"
      >Download Mobile App</omgui-button
    >
  </div>

  <div class="d-none d-sm-flex rounded-bottom">
    <div class="modal-body-item border-right">
      <div class="mx-3 h-100 d-flex justify-content-between flex-column">
        <h4 class="m-0 h6">Text Download Link</h4>
        <p class="m-0">Have a text sent with download link</p>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="mt-2">
          <om-phone-number-input
            [overrideDisplayError]="true"
            [formControlName]="formControlName"
            (errorMessageEmitter)="setErrorMessage($event)"
          ></om-phone-number-input>
          <span class="text-danger fs-6" *ngIf="formErrorMessage">{{ formErrorMessage }}</span>
          <omgui-button class="mt-3" type="submit" [disabled]="submitting"> Send Link </omgui-button>
        </form>
      </div>
    </div>

    <div class="modal-body-item">
      <div class="mx-3">
        <h4 class="h6">QR Code Download</h4>
        <p>Point phone camera at the QR code and click link</p>
        <img class="qr-code" src="/assets/images/app-store-redirect-qr-code.svg" alt="QR Code to download mobile app" />
      </div>
    </div>
  </div>
</div>
