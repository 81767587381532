<account-set-up-value-prop *ngIf="showValueProp"></account-set-up-value-prop>
<div class="container mt-lg-5 mt-2">
  <form class="col-12 col-lg-10 offset-lg-1 px-lg-5 d-flex flex-column" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 class="text-center">Let's get your account set up.</h1>
    <p class="text-center mb-5 mb-xl-5">
      There’s no paperwork in the office. Just provide a few quick details now to get set up.
    </p>
    <div class="col-12 col-md-8 offset-md-2">
      <div class="row">
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="" label="Legal First Name">
            <input
              #firstName
              type="text"
              class="form-control text-field"
              placeholder="Enter first name"
              formControlName="firstName"
              required="true"
              autofocus
            />
          </om-label-wrapper>
        </div>
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="" label="Legal Last Name">
            <input
              type="text"
              class="form-control text-field"
              placeholder="Enter last name"
              required="true"
              formControlName="lastName"
            />
          </om-label-wrapper>
        </div>
        <a
          class="col-12 mb-3"
          *ngIf="!showPreferredName"
          href=""
          (click)="$event.preventDefault(); showPreferredName = true"
        >
          Add a preferred name (optional)
        </a>
        <div *ngIf="showPreferredName" class="col-12 col-lg-6 mb-3">
          <om-label-wrapper class="" label="Preferred Name">
            <input
              type="text"
              class="form-control text-field"
              placeholder="Enter preferred name"
              formControlName="preferredName"
            />
          </om-label-wrapper>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3"><om-address-input #address [address]="form.get('address')"></om-address-input></div>
      </div>
      <div class="row">
        <div class="col-12">
          <om-label-wrapper class="" label="Phone Number">
            <om-phone-number-input #phoneNumber formControlName="phoneNumber"></om-phone-number-input>
          </om-label-wrapper>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-1 pr-0">
          <input type="checkbox" class="d-inline w-auto" formControlName="textAppToPhone" />
        </div>
        <div class="col-11 pl-0">
          <p class="mb-0 font-weight-light">Text me a link to download the app</p>
          <small class="text-muted">Get 24/7 care over video chat and messages at no cost</small>
        </div>
      </div>
      <div><om-submit-button class="col-12" label="Next" [submitting]="submitting"></om-submit-button></div>
    </div>
  </form>
</div>
