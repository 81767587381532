import { FetchAppointmentBookingStateCache_patient_appointmentBookingSession_appointmentBookingStateCache_appointmentBookingState_serviceArea as CachedServiceArea } from '@app/appointment/__generated__/FetchAppointmentBookingStateCache';
import { AppointmentDetails_appointment_office_serviceArea as GraphQLServiceArea } from '@app/appointment/appointment-reschedule-reason/__generated__/AppointmentDetails';

export class ServiceArea {
  id: number;
  name: string;
  code: string;
  default_office_id: number;
  latitude: number | null;
  longitude: number | null;
  officesCount: number | null;
  registration_hidden: boolean;
  virtual: boolean;
  at_capacity: boolean | null;
  near_capacity: boolean | null;

  constructor(attrs: Record<string, any> = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.code = attrs.code;
    this.default_office_id = attrs.default_office_id;
    this.registration_hidden = attrs.registration_hidden;
    this.virtual = attrs.virtual;
    this.at_capacity = attrs.at_capacity;
    this.near_capacity = attrs.near_capacity;
  }

  static fromApiV2(response: Record<string, any>): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = response.id;
    serviceArea.name = response.name;
    serviceArea.code = response.code;
    serviceArea.default_office_id = response.default_office_id;
    serviceArea.latitude = response.latitude;
    serviceArea.longitude = response.longitude;
    serviceArea.officesCount = response.offices_count;
    serviceArea.registration_hidden = response.registration_hidden;
    serviceArea.virtual = response.virtual;
    serviceArea.at_capacity = response.at_capacity;
    serviceArea.near_capacity = response.near_capacity;
    return serviceArea;
  }

  static fromGraphQL(response: Pick<GraphQLServiceArea, 'id' | 'name' | 'virtual'>): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = parseInt('' + response.id, 10);
    serviceArea.name = response.name;
    serviceArea.virtual = response.virtual;

    return serviceArea;
  }

  static fromAppointmentBookingSession(cachedServiceArea: CachedServiceArea): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = +cachedServiceArea.id;
    serviceArea.name = cachedServiceArea.name;
    serviceArea.default_office_id = +cachedServiceArea.defaultOffice.id;

    return serviceArea;
  }

  toApiV2() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      default_office_id: this.default_office_id,
      registration_hidden: this.registration_hidden,
      virtual: this.virtual,
      at_capacity: this.at_capacity,
      near_capacity: this.near_capacity,
    };
  }
}
