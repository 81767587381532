export enum CareTopic {
  OfficeVisit = 'officeVisit',
  RemoteVisit = 'remoteVisit',
  CovidCare = 'covidCare',
  CovidCareWithHomeKits = 'covidCareWithHomeKits',
  PediatricMeetAndGreet = 'pediatricMeetAndGreet',
  Wellbeing = 'wellbeing',
  MentalHealth = 'mentalHealth',
  ChronicConditions = 'chronicConditions',
  SexualHealth = 'sexualHealth',
}
