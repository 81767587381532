import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import lodashMap from 'lodash-es/map';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { ServiceArea } from '@app/shared/service-area';

export const EXPERIMENT_OFF = 'OFF';
export const EXPERIMENT_ON_SHOW_VIRTUAL_SERVICE_AREA = 'ON VARIANT';
export const EXPERIMENT_ON_HIDE_VIRTUAL_SERVICE_AREA = 'ON CONTROL';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreasDataService extends DefaultDataService<ServiceArea> {
  publicServiceAreaPath = '/api/v2/public/service_areas';

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private apiService: ApiService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    super('ServiceArea', http, httpUrlGenerator);
  }

  getAll(): Observable<ServiceArea[]> {
    const showVirtualServiceArea$ = this.launchDarklyService.featureFlag$(
      FeatureFlags.PC_CONSUMER_VIRTUAL_SERVICE_AREA,
      EXPERIMENT_OFF,
    );
    return combineLatest([showVirtualServiceArea$, this.apiService.get(this.publicServiceAreaPath)]).pipe(
      map(([showVirtualServiceAreas, result]) => [
        showVirtualServiceAreas,
        lodashMap(result as any, sa => new ServiceArea(sa)) as ServiceArea[],
      ]),
      map(([showVirtualServiceAreas, allServiceAreas]) => {
        const allServiceAreaResults: ServiceArea[] = allServiceAreas as ServiceArea[];
        const virtualServiceArea: ServiceArea = allServiceAreaResults.find(sa => sa.virtual && sa.code === 'tmp');

        if (virtualServiceArea) {
          virtualServiceArea.name = 'Virtual';
        }

        const filteredServiceAreaResults: ServiceArea[] = allServiceAreaResults
          .filter(serviceArea => !serviceArea.registration_hidden && !serviceArea.virtual)
          .sort((a, b) => a.name.localeCompare(b.name));
        if (showVirtualServiceAreas === EXPERIMENT_ON_SHOW_VIRTUAL_SERVICE_AREA) {
          filteredServiceAreaResults.push(virtualServiceArea);
        }

        return filteredServiceAreaResults;
      }),
    );
  }

  getById(key: number): Observable<ServiceArea> {
    return this.apiService
      .get(`${this.publicServiceAreaPath}/${key}`)
      .pipe(map((result: any) => new ServiceArea(result)));
  }
}
