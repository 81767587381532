import { addDays, differenceInDays, endOfMonth, isBefore } from 'date-fns';

import { Coupon } from '@app/shared/coupon';
import { formatDate } from '@app/shared/date-format.pipe';

import { MembershipEvent } from './membership-event';
import { ApiV2Response } from './membership.service';

interface RenewalPlan {
  amount: number;
}

export const DEFAULT_CURRENT_RENEW_PLAN = {
  amount: 199.0,
  interval: 'year',
  stripe_id: '199',
};

export enum ExpirationAction {
  RENEW = 'renew',
  EXPIRE = 'expire',
}

export enum MembershipPlan {
  B2B = 'B2bPlan',
  GUEST = 'GuestPlan',
  SELF_PAID = 'StripePlan',
}

export enum MembershipStatus {
  CURRENT = 'current',
  DEACTIVATED = 'deactivated',
  EXPIRED = 'expired',
  PENDING = 'pending',
}

export enum CreditCardBrand {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
  AMEX = 'American Express',
  DISCOVER = 'Discover',
}

export type CreditCardBrandName = CreditCardBrand | string;

class Expiration {
  date: Date;
  action: ExpirationAction;
}

export class CreditCard {
  brand: CreditCardBrandName;
  last4: string;
  expirationDate: string;
  isExpired: boolean;
}

interface ReturnToWorkClient {
  showHomepageSurveyCard: boolean;
}

interface B2bCompany {
  id: number;
  displayName: string;
  returnToWork?: boolean;
  returnToWorkClient?: ReturnToWorkClient;
}

export const SOON_EXPIRATION_IN_DAYS = 30;

export class Membership {
  b2bCompany?: B2bCompany;
  canBookVisit: boolean;
  canCancel: boolean;
  canConvertToDirect: boolean;
  canReactivate: boolean;
  canUpdateB2bCode: boolean;
  canUpdateBilling: boolean;
  enterprisePedsRegistrationEnabled: boolean;
  isActive: boolean;
  creditCard?: CreditCard;
  expiration: Expiration;
  patientId: number;
  patientStatus: string;
  planId: number;
  planRtwReleaseRequired: boolean;
  planType: MembershipPlan;
  previousB2bCompany: B2bCompany;
  renewalPlan?: RenewalPlan;
  status: string;
  validUntil: string;
  omMembershipType: string;
  isEnterpriseTransitional: boolean;
  trialUntil: string;
  trialAutoRenew: boolean;
  promotionDiscountCoupon?: Coupon;
  events: MembershipEvent[] = [];
  isPaidWithAfterpay: boolean;

  constructor() {}

  static fromApiV2(response: ApiV2Response): Membership {
    const membership = new Membership();
    membership.canBookVisit = response.can_book_visit;
    membership.canCancel = response.can_cancel;
    membership.canConvertToDirect = response.can_convert_to_direct;
    membership.canReactivate = response.can_reactivate;
    membership.canUpdateB2bCode = response.can_update_b2b_code;
    membership.canUpdateBilling = response.can_update_billing;
    membership.enterprisePedsRegistrationEnabled = response.enterprise_peds_registration_enabled;
    membership.isActive = response.is_active;
    membership.patientStatus = response.patient_status;
    membership.planId = response.plan_id;
    membership.planRtwReleaseRequired = response.release_required_for_rtw_screener;
    membership.planType = response.plan_type as MembershipPlan;
    membership.status = response.status;
    membership.validUntil = response.valid_until;
    membership.omMembershipType = response.om_membership_type;
    membership.isEnterpriseTransitional = response.is_enterprise_transitional || false;
    membership.trialUntil = response.trial_until;
    membership.trialAutoRenew = !response.deactivate_at_trial_end && !!response.trial_until;
    membership.events = response.events;
    membership.isPaidWithAfterpay = response.is_paid_with_afterpay;

    if (response.consumer_promo_discount) {
      membership.promotionDiscountCoupon = Coupon.fromApiV2(response.consumer_promo_discount);
    }

    if (response.b2b_company) {
      membership.b2bCompany = {
        id: response.b2b_company.id,
        displayName: response.b2b_company.display_name,
        returnToWork: response.b2b_company.return_to_work,
      };

      if (response.b2b_company.return_to_work_client) {
        membership.b2bCompany.returnToWorkClient = {
          showHomepageSurveyCard: response.b2b_company.return_to_work_client.show_homepage_survey_card,
        };
      }
    }

    if (response.previous_enterprise_membership && response.previous_enterprise_membership.b2b_company) {
      membership.previousB2bCompany = {
        id: response.previous_enterprise_membership.b2b_company.id,
        displayName:
          response.previous_enterprise_membership.b2b_company.display_name ||
          response.previous_enterprise_membership.b2b_company.name,
      };
    }

    if (response.renewal_plan) {
      membership.renewalPlan = { amount: response.renewal_plan.amount / 100 };
    }

    if (response.expiration_date) {
      const expiration = new Expiration();
      expiration.date = response.expiration_date.date ? new Date(response.expiration_date.date) : new Date();
      expiration.action = <ExpirationAction>response.expiration_date.action;
      membership.expiration = expiration;
    }

    if (response.credit_card) {
      const { last4, brand, exp_month, exp_year } = response.credit_card;
      const creditCard = new CreditCard();
      creditCard.brand = <CreditCardBrandName>brand;
      creditCard.last4 = last4;

      const expirationDate: Date = endOfMonth(new Date(Number(exp_year), Number(exp_month) - 1, 1));
      creditCard.expirationDate = formatDate(expirationDate, 'MM-dd-yyyy hh:mm:ss');
      creditCard.isExpired = isBefore(expirationDate, new Date());

      membership.creditCard = creditCard;
    }

    // Treat expiring as a state like everything else
    if (membership.isEnterpriseTransitional && !membership.isExpired()) {
      // The status is active, however the b2b member is expiring in this case
      // the action we need to reflect is expire, in this case
      if (membership.expiration) {
        membership.expiration.action = ExpirationAction.EXPIRE;
      }
    }

    return membership;
  }

  hasLimitedAccess(): boolean {
    return this.planType === MembershipPlan.GUEST;
  }

  isExpired(): boolean {
    return this.status === MembershipStatus.EXPIRED || this.status === MembershipStatus.DEACTIVATED;
  }

  isEnterpriseAndExpiring(): boolean {
    return this.isEnterpriseTransitional && !this.isExpired();
  }

  isExpiringSoon(): boolean {
    const threshold = addDays(new Date(), SOON_EXPIRATION_IN_DAYS);
    return differenceInDays(threshold, new Date(this.validUntil)) >= 0;
  }

  inFreeTrial(): boolean {
    return !!this.trialUntil;
  }

  isPending(): boolean {
    return this.status === MembershipStatus.PENDING;
  }

  hasReadOnlyMessaging(): boolean {
    return this.hasLimitedAccess() || this.isExpired();
  }

  isB2b(): boolean {
    return this.planType === MembershipPlan.B2B;
  }

  isSelfPaid(): boolean {
    return this.planType === MembershipPlan.SELF_PAID;
  }

  isGuest(): boolean {
    return this.planType === MembershipPlan.GUEST;
  }

  isCurrent(): boolean {
    return this.status === MembershipStatus.CURRENT;
  }

  isConsumer(): boolean {
    return this.omMembershipType.toUpperCase() === 'CONSUMER';
  }

  isVirtual(): boolean {
    return !this.canConvertToDirect;
  }

  isOMNow(adultVirtualConsumerExperimentEnabled = false, pediatricVirtualConsumerExperimentEnabled = false): boolean {
    if (adultVirtualConsumerExperimentEnabled || pediatricVirtualConsumerExperimentEnabled) {
      return this.isVirtual();
    }
    return this.isVirtual() && this.isB2b();
  }
}
