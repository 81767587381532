import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { EXPERIMENT_OFF, EXPERIMENT_ON_SHOW_VIRTUAL_SERVICE_AREA } from '@app/service-areas/service-areas-data.service';
import { EmailVerificationService } from '@app/shared/email-verification.service';

export interface MixpanelEmailStatuses {
  login_email_status?: string;
  preferred_email_status?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationAnalyticsHelperService {
  private membershipType$: Observable<string>;
  private emailStatuses$: Observable<MixpanelEmailStatuses>;

  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private emailVerificationService: EmailVerificationService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    this.emailStatuses$ = combineLatest([
      userService.getUser().pipe(map(user => user.loginEmailIsPreferred())),
      emailVerificationService.getVerificationStatus$().pipe(map(status => status.emailVerified)),
    ]).pipe(
      map(([loginIsMain, emailVerified]) => {
        const status = emailVerified ? 'Verified' : 'Unverified';
        const emailStatus: MixpanelEmailStatuses = loginIsMain // This variable is only for enforcing type checking
          ? { login_email_status: status }
          : { preferred_email_status: status };
        return emailStatus;
      }),
      shareReplay(1),
    );

    const virtualConsumerRegAdultExperiment$ = this.launchDarklyService
      .featureFlag$(FeatureFlags.PC_CONSUMER_VIRTUAL_SERVICE_AREA, EXPERIMENT_OFF)
      .pipe(
        take(1),
        map(experimentEnabled => experimentEnabled === EXPERIMENT_ON_SHOW_VIRTUAL_SERVICE_AREA),
      );

    const virtualConsumerRegPediatricExperiment$ = this.launchDarklyService
      .featureFlag$(FeatureFlags.PC_CONSUMER_VIRTUAL_SERVICE_AREA_PEDIATRIC, EXPERIMENT_OFF)
      .pipe(
        take(1),
        map(experimentEnabled => experimentEnabled === EXPERIMENT_ON_SHOW_VIRTUAL_SERVICE_AREA),
      );

    this.membershipType$ = combineLatest([
      this.membershipService.getMembership(),
      virtualConsumerRegAdultExperiment$,
      virtualConsumerRegPediatricExperiment$,
    ]).pipe(
      map(([membership, virtualConsumerRegAdultExperimentEnabled, virtualConsumerRegPediatricExperimentEnabled]) => {
        const isVirtual = membership.isOMNow(
          virtualConsumerRegAdultExperimentEnabled,
          virtualConsumerRegPediatricExperimentEnabled,
        );
        return isVirtual ? 'OM Now' : membership.omMembershipType;
      }),
      shareReplay(1),
    );
  }

  get emailStatuses(): Observable<MixpanelEmailStatuses> {
    return this.emailStatuses$.pipe(take(1));
  }

  get membershipType(): Observable<string> {
    return this.membershipType$.pipe(take(1));
  }
}
