import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import includes from 'lodash-es/includes';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';
import { take } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import { AnalyticsService } from '@app/core/analytics.service';
import { ConfigService } from '@app/core/config.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipStatus } from '@app/core/membership';
import {
  MODULE_ACCOUNT_SETUP_PAGE,
  MODULE_COMPANY_CODE_PAGE,
  MODULE_CONCIERGE_PAGE,
  MODULE_DIRECT_SIGN_UP_FAMILY_PAGE,
  MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
  MODULE_EMAIL_VERIFICATION_PAGE,
  MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE,
  MODULE_PEDIATRIC_TERMS_OF_SERVICE,
  MODULE_PERSONAL_DETAILS_PAGE,
  MODULE_SERVICE_AREA_PAGE,
  MODULE_VERIFIED_ENTERPRISE_REGISTRATION_LOADING_PAGE,
  MODULE_VERIFIED_EXISTING_USER_PAGE,
  MODULE_VERIFIED_EXPIRED_TOKEN_PAGE,
  MODULE_WORK_EMAIL_VALIDATION_PAGE,
  MP_EVENT_EMAIL_UNVERIFIED,
  MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED,
  MP_EVENT_EMAIL_VERIFICATION_ACTION,
  MP_EVENT_EMAIL_VERIFIED,
  MP_EVENT_LOGIN_CLICKED,
  MP_EVENT_PAGE_VIEWED,
  MP_EVENT_REG_INPUT_SUBMITTED,
  MP_FLOW_REGISTRATION,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import {
  ChildBirthTypeSelectedEvent,
  ChildIntakeEvent,
  EmailVerificationEvent,
  LoggedMember,
  PediatricsEmailVerificationEvent,
  PedsUnavailableModalShownEvent,
  PediatricsTosAnalyticsEvent,
} from '@app/shared/analytics';
import { ServiceArea } from '@app/shared/service-area';

export interface RegInputErroredProperties {
  error: string;
  formField: string;
  isWhitelist?: boolean;
  companyId?: number;
  module: string;
}

export interface LinkClickedProperties {
  isWhitelist?: boolean;
  companyId?: number;
  serviceArea?: string;
  module: string;
}

export interface RegInputSubmittedProperties {
  isWhitelist?: boolean;
  isLoggedIn?: boolean;
  isPedsShown?: boolean;
  companyId?: number;
  serviceArea?: string;
  companyCode?: string;
  module: string;
  moduleVariant?: string;
  experimentName?: string;
  experimentVariationName?: string;
  workEmailAddress?: string;
  fastForwarding?: boolean;
}

export interface BasicEventProperties {
  module: string;
  moduleVariant?: string;
  isWhitelist?: boolean;
  source?: string;
  service_area?: string;
  experimentName?: string;
  experimentVariantName?: string;
  experimentVariationName?: string;
  companyId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class EnterpriseRegistrationAnalyticsService extends AnalyticsService {
  static FLOW = MP_FLOW_REGISTRATION;
  static OMNOWFLOW = 'OM Now Navigation';
  static POST_REGISTRATION_FLOW = 'Post Registration';
  static DIRECT_SIGNUP_FLOW = 'Direct Sign Up';
  static MEMBERSHIP_TYPE = 'Enterprise';
  static VERSION = 'Enterprise Registration Q12020';
  private GTM_FILTERED_FIELDS = ['work_email_address'];

  constructor(
    config: ConfigService,
    store: Store<State>,
    mixpanel: MixpanelService,
    launchDarkly: LaunchDarklyService,
    private gtmService: GoogleTagManagerService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {
    super(mixpanel, store, launchDarkly);
  }

  regInputErrored(props: RegInputErroredProperties) {
    return this.trackMultiPlatformEvent('Input Error Encountered', {
      error: props.error,
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      form_field: props.formField,
      is_whitelist: props.isWhitelist,
      company_id: props.companyId,
      module: props.module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  resetPasswordClicked(props: LinkClickedProperties) {
    return this.trackMultiPlatformEvent('Reset Password Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: props.isWhitelist,
      company_id: props.companyId,
      module: props.module,
      service_area: props.serviceArea,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  emailAdminClicked(props: LinkClickedProperties) {
    return this.trackMultiPlatformEvent('Email Admin Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: props.isWhitelist,
      company_id: props.companyId,
      module: props.module,
      service_area: props.serviceArea,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  regInputSubmitted({
    serviceArea: service_area,
    isWhitelist: is_whitelist,
    module,
    companyCode: company_code,
    moduleVariant: module_variant,
    experimentName: experiment_name,
    experimentVariationName: experiment_variation_name,
    workEmailAddress: work_email_address,
    fastForwarding: fast_forwarding,
  }: RegInputSubmittedProperties) {
    const trackingParams = pickBy(
      {
        flow: EnterpriseRegistrationAnalyticsService.FLOW,
        flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
        is_whitelist,
        module,
        om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        service_area,
        company_code,
        module_variant,
        experiment_name,
        experiment_variation_name,
        work_email_address,
        fast_forwarding,
      },
      v => !isNil(v),
    );
    return this.trackMultiPlatformEvent(MP_EVENT_REG_INPUT_SUBMITTED, trackingParams);
  }

  sendCompanyCodeViewed() {
    return this.trackMultiPlatformEvent('Send Company Code Viewed', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: false,
      module: MODULE_COMPANY_CODE_PAGE,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  enterActivationCodeClicked({ isWhitelist, serviceArea }: { isWhitelist: boolean; serviceArea: ServiceArea }) {
    return this.trackMultiPlatformEvent('Enter Activation Code Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_WORK_EMAIL_VALIDATION_PAGE,
      submodule: 'Email Error Modal',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: isWhitelist,
      service_area: serviceArea,
    });
  }

  emailErrorModalViewed({ isWhitelist, serviceArea }: { isWhitelist: boolean; serviceArea: ServiceArea }) {
    return this.emailErrorTrackEvent('Email Error Modal Viewed', isWhitelist, serviceArea);
  }

  emailErrorModalClosed({ isWhitelist, serviceArea }: { isWhitelist: boolean; serviceArea: ServiceArea }) {
    return this.emailErrorTrackEvent('Email Error Modal Closed', isWhitelist, serviceArea);
  }

  alreadyRegisteredModalViewed() {
    return this.alreadyRegisteredTrackEvent('Already Registered Modal Viewed');
  }

  alreadyRegisteredModalClosed() {
    return this.alreadyRegisteredTrackEvent('Already Registered Modal Closed');
  }

  alreadyRegisteredModalLoginClicked() {
    return this.alreadyRegisteredTrackEvent('Already Registered Modal Login Clicked');
  }

  private emailErrorTrackEvent(eventName: string, isWhitelist: boolean, serviceArea: ServiceArea) {
    return this.trackMultiPlatformEvent(eventName, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_WORK_EMAIL_VALIDATION_PAGE,
      submodule: 'Email Error Modal',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: isWhitelist,
      service_area: serviceArea,
    });
  }

  private alreadyRegisteredTrackEvent(eventName: string) {
    return this.trackMultiPlatformEvent(eventName, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_PERSONAL_DETAILS_PAGE,
      submodule: 'Personal Details Already Registered Modal',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: true,
    });
  }

  pageViewed(props: BasicEventProperties) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, this.mapBasicEventFromProps(props));
  }

  loginClicked(props: BasicEventProperties) {
    return this.trackMultiPlatformEvent(MP_EVENT_LOGIN_CLICKED, this.mapBasicEventFromProps(props));
  }

  redirectToHomeClicked(props: BasicEventProperties) {
    return this.trackMultiPlatformEvent('Continue Flow Clicked', this.mapBasicEventFromProps(props));
  }

  redirectToConsentFormClicked(props: BasicEventProperties) {
    return this.trackMultiPlatformEvent('Complete Consent Form Clicked', this.mapBasicEventFromProps(props));
  }

  membershipSelectionStepViewed(props: RegInputSubmittedProperties) {
    const { isWhitelist, isLoggedIn, isPedsShown, companyId, module } = props;

    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      is_logged_in: isLoggedIn,
      is_peds_shown: isPedsShown,
      company_id: companyId,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  myBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, module, fastForwarding } = props;
    return this.trackMultiPlatformEvent('My Benefit Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      fast_forwarding: fastForwarding,
      is_whitelist: isWhitelist,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  spouseBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, module } = props;
    return this.trackMultiPlatformEvent('Spouse/Dependent Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  childBenefitSelected(props: RegInputSubmittedProperties) {
    const { isWhitelist, isLoggedIn, companyId, module } = props;
    return this.trackMultiPlatformEvent('Child Selected', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      is_whitelist: isWhitelist,
      is_logged_in: isLoggedIn,
      company_id: companyId,
      module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  genderClicked({ module, companyId }: { module: string; companyId: number }) {
    return this.trackWithDefaultProperties('Add Gender Information Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      om_membership_status: MembershipStatus.PENDING,
      company_id: companyId,
    });
  }

  genderInformationAdded({ module, companyId }: { module: string; companyId: number }) {
    return this.trackWithDefaultProperties('Gender Information Added', {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: module,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      om_membership_status: MembershipStatus.PENDING,
      company_id: companyId,
    });
  }

  invalidAddressSelected(error: string, isWhitelist: boolean, module: string = MODULE_ACCOUNT_SETUP_PAGE) {
    return this.trackMultiPlatformEvent('Invalid Address Selected', {
      error,
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      form_field: 'Address',
      is_whitelist: isWhitelist,
      module,
      om_membership_type: 'Enterprise',
    });
  }

  continueAsAVirtualMemberClicked() {
    return this.trackMultiPlatformEvent('Continue As Virtual Member Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.OMNOWFLOW,
      module: MODULE_SERVICE_AREA_PAGE,
      submodule: 'Virtual Care Modal',
    });
  }

  directSignupStartedPostRegistration() {
    return this.trackMultiPlatformEvent('Direct Sign Up Started', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Enterprise Registration Confirmation Page',
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: this.isWhitelist,
      is_logged_in: true,
    });
  }

  directSignupTypeSelected(type: string) {
    return this.trackMultiPlatformEvent('Direct Sign Up Type Selected', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
      direct_sign_up_type: type,
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
      is_pediatrics: type === 'Child',
    });
  }

  directSignupSubmitted() {
    return this.trackDirectSignupEvent('Direct Sign Up Submitted');
  }

  directSignupCanceled() {
    return this.trackDirectSignupEvent('Direct Sign Up Canceled');
  }

  private trackDirectSignupEvent(eventName: string) {
    return this.trackMultiPlatformEvent(eventName, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupSelectionPageViewed() {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: MODULE_DIRECT_SIGN_UP_SELECTION_PAGE,
      is_whitelist: this.isWhitelist,
      is_pediatrics: true,
    });
  }

  directSignupFamilyPageViewed(source: string) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: MODULE_DIRECT_SIGN_UP_FAMILY_PAGE,
      membership_status: MembershipStatus.CURRENT,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
      is_whitelist: this.isWhitelist,
      source,
    });
  }

  adultDirectSignupPageViewed() {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  trackAnotherDependentClicked() {
    return this.trackMultiPlatformEvent('Register Another Dependent Clicked', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Confirmation Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupGenderDetailsExpanded() {
    return this.trackMultiPlatformEvent('Show Gender Details Expanded', {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Dependent Information Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  directSignupConfirmationPageViewed() {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.DIRECT_SIGNUP_FLOW,
      module: 'Direct Sign Up Confirmation Page',
      direct_sign_up_type: 'Spouse',
      is_logged_in: true,
      is_whitelist: this.isWhitelist,
    });
  }

  emailVerification(props: BasicEventProperties, verified: boolean) {
    if (verified) {
      return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_VERIFIED, this.mapBasicEventFromProps(props));
    } else {
      return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_UNVERIFIED, this.mapBasicEventFromProps(props));
    }
  }

  accountLocked(props: BasicEventProperties) {
    return this.trackWithDefaultProperties(
      MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED,
      pickBy(
        {
          flow: this.flowForModule(props.module),
          flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
          is_whitelist: props.isWhitelist,
          module: props.module,
          module_variant: props.moduleVariant,
          om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        },
        v => !isNil(v),
      ),
    );
  }

  trackVerifiedLoadingPageViewed(companyId?: string) {
    return this.trackMultiPlatformEvent(
      MP_EVENT_PAGE_VIEWED,
      pickBy(
        {
          flow: EnterpriseRegistrationAnalyticsService.FLOW,
          module: MODULE_VERIFIED_ENTERPRISE_REGISTRATION_LOADING_PAGE,
          om_membership_status: MembershipStatus.PENDING,
          om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
          company_id: companyId,
        },
        v => !isNil(v),
      ),
    );
  }

  trackVerifiedExpiredTokenPageViewed() {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_VERIFIED_EXPIRED_TOKEN_PAGE,
      om_membership_status: MembershipStatus.PENDING,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  trackVerifiedExistingUserPageViewed() {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: EnterpriseRegistrationAnalyticsService.FLOW,
      module: MODULE_VERIFIED_EXISTING_USER_PAGE,
      om_membership_status: MembershipStatus.CURRENT,
      om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
    });
  }

  childIntakePageViewed(params: ChildIntakeEvent) {
    let source_property = '';
    const module_variant = params.source === 'familyFlowConfirmationPage' ? 'Me and My Child' : '';
    if (['confirmationPagePromoCard', 'familyFlowConfirmationPage'].indexOf(params.source) !== -1) {
      source_property = 'Adult Registration Confirmation Page';
    }
    const properties = {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
      module_variant: module_variant,
      source: source_property,
    };

    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, properties);
  }

  childBirthTypeSelected(params: ChildBirthTypeSelectedEvent) {
    return this.trackMultiPlatformEvent('Child Birth Type Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      birth_type: params.unborn ? 'Unborn' : 'Born',
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
    });
  }

  pedsUnavailableModalShown(params: PedsUnavailableModalShownEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Pediatrics Location Error Modal',
      service_area: params.serviceArea.name,
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
    });
  }

  guardianConfirmationPageViewed(params: LoggedMember) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
    });
  }

  childAccountCreationPageViewed(params: LoggedMember) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
    });
  }

  tosPageViewed(params: PediatricsTosAnalyticsEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Peds TOS page',
      is_pediatrics: params.isPediatrics,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
    });
  }

  legalDocumentSigned(params: PediatricsTosAnalyticsEvent) {
    return this.trackMultiPlatformEvent('Legal Document Signed', {
      flow: 'Direct Sign Up',
      module: MODULE_PEDIATRIC_TERMS_OF_SERVICE,
      is_pediatrics: params.isPediatrics,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
    });
  }

  emailVerificationPageViewed(props: PediatricsEmailVerificationEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      is_pediatrics: true,
      om_membership_type: props.membershipType,
      service_area: props.serviceArea.name,
    });
  }

  emailVerificationSuccess(props: PediatricsEmailVerificationEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_VERIFIED, {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      is_pediatrics: true,
      om_membership_type: props.membershipType,
      service_area: props.serviceArea.name,
    });
  }

  emailVerificationUnverified(props: PediatricsEmailVerificationEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_UNVERIFIED, {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      is_pediatrics: true,
      om_membership_type: props.membershipType,
      service_area: props.serviceArea.name,
    });
  }

  emailVerificationLockedOut(props: PediatricsEmailVerificationEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED, {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      is_pediatrics: true,
      om_membership_type: props.membershipType,
      service_area: props.serviceArea.name,
    });
  }

  emailVerificationAction(props: EmailVerificationEvent) {
    return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_VERIFICATION_ACTION, {
      flow: props.flow || 'Post Registration',
      flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
      module: props.module,
      module_variant: props.moduleVariant,
      is_whitelist: props.isWhitelist,
      is_pediatrics: props.isPediatrics,
      om_membership_type: props.membershipType,
      service_area: props?.serviceArea?.name,
      action_selected: props.action,
    });
  }

  private trackMultiPlatformEvent(eventName: string, props: any) {
    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.ENTERPRISE_REGISTRATION_GTM_ANALYTICS)
      .pipe(take(1))
      .subscribe({
        next: flagActive => {
          if (flagActive) {
            this.trackGoogleTagManagerEvent(eventName, props);
          }
        },
      });

    return this.trackWithDefaultProperties(eventName, props);
  }

  private trackGoogleTagManagerEvent(eventName: string, props: any) {
    let gtmData: Record<string, any> = props;
    gtmData.event = eventName;
    gtmData = pickBy(gtmData, (value, key) => !includes(this.GTM_FILTERED_FIELDS, key));
    this.gtmService.pushTag(gtmData);
  }

  private mapBasicEventFromProps(props: BasicEventProperties): any {
    return pickBy(
      {
        flow: this.flowForModule(props.module),
        flow_version: EnterpriseRegistrationAnalyticsService.VERSION,
        is_whitelist: props.isWhitelist,
        module: props.module,
        module_variant: props.moduleVariant,
        om_membership_type: EnterpriseRegistrationAnalyticsService.MEMBERSHIP_TYPE,
        om_membership_status: MembershipStatus.CURRENT,
        source: props.source,
        service_area: props.service_area,
        experiment_name: props.experimentName,
        experiment_variant_name: props.experimentVariantName,
        experiment_variation_name: props.experimentVariationName,
        company_id: props.companyId,
      },
      v => !isNil(v),
    );
  }

  private flowForModule(moduleName: string) {
    if (moduleName === MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE || moduleName === MODULE_CONCIERGE_PAGE) {
      return EnterpriseRegistrationAnalyticsService.POST_REGISTRATION_FLOW;
    }

    return EnterpriseRegistrationAnalyticsService.FLOW;
  }
}
