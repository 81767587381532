import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { MODULE_EID_VALIDATION_PAGE } from '@app/core/mixpanel.constants';
import { MembershipType } from '@app/registration/enterprise/membership-type';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { WhitelistedEmployee, WhitelistedEmployee_whitelistedEmployee } from '../__generated__/WhitelistedEmployee';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import {
  AlreadyRegisteredError,
  EmployeeNotFoundError,
  GENERIC_WHITELISTING_ERROR_MESSAGE,
  INVALID_CONVERSION_ERROR_MESSAGE,
  InvalidConversionError,
  PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE,
} from '../whitelisted-employee-errors';
import { WhitelistedEmployeeGraphQL } from '../whitelisted-employee-graphql.service';
import { EmployeeIdStepComponent } from './employee-id-step.component';

@Injectable()
export class EmployeeIdConfig extends RegistrationStep {
  EMPLOYEE_ID_ERROR = `The ID number is invalid or already in use. If you’ve lost access to your account, <a href="${this.linksService.forgotPassword}">reset your password</a> or contact your benefits team.`;

  GA_ACTION = 'EmployeeId';
  GA_LABEL = 'ActivateYourMembership';

  MODULE = MODULE_EID_VALIDATION_PAGE;
  SYSTEM_ERROR_MESSAGE = 'There was an error submitting your employee ID number. Please try again later.';

  captchaNeeded = true;
  component = EmployeeIdStepComponent;
  componentInstance: EmployeeIdStepComponent;
  progress = 0;
  form: FormGroup = this.formBuilder.group({
    b2bCompanyId: ['', Validators.required],
    employeeId: ['', Validators.required],
    whitelistedEmployeeId: '',
    workEmail: '',
  });

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private readonly linksService: LinksService,
    private formBuilder: FormBuilder,
    private whitelistedEmployeeGraphQL: WhitelistedEmployeeGraphQL,
  ) {
    super();
  }

  canGoBack(): boolean {
    return false;
  }

  initComponent(component: EmployeeIdStepComponent) {
    component.form = this.form;
    this.componentInstance = component;
    if (this.form.controls.b2bCompanyId.invalid) {
      this.componentInstance.noB2bCompanyIdError = true;
    }
  }

  patchParams(params: any) {
    const b2bCompanyId = params.b2b_company_id;
    const employeeId = params.employee_id;
    const workEmail = params.workEmail;

    if (b2bCompanyId) {
      this.form.patchValue({ b2bCompanyId });
    }
    if (employeeId) {
      this.form.patchValue({ employeeId });
    }
    if (workEmail) {
      this.form.patchValue({ workEmail });
    }
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    const { form } = this;
    const { employeeId, b2bCompanyId }: { employeeId: string; b2bCompanyId: string } = form.value;
    const { fastForwarding } = state;

    if (form.valid) {
      state.details.b2bCompanyId = +b2bCompanyId;
      return captcha.getToken().pipe(
        switchMap((reCaptchaToken: string) =>
          this.whitelistedEmployeeGraphQL.fetch({ employeeId, b2bCompanyId, reCaptchaToken }),
        ),
        switchMap((result: ApolloQueryResult<WhitelistedEmployee>) => {
          const whitelistedEmployee = result.data.whitelistedEmployee;
          this.updateForms(state, whitelistedEmployee);

          if (whitelistedEmployee) {
            const { b2bCompany, id } = whitelistedEmployee;
            state.b2bCompany = b2bCompany;
            state.details.whitelistedEmployeeId = +id;
            state.details.employeeId = employeeId;
            state.details.workEmail = state.whitelistedEmployee.workEmail;
            this.trackSubmission({ isWhitelist: true, fastForwarding });
            return this.setNextStep(state, whitelistedEmployee, captcha);
          } else {
            throw new EmployeeNotFoundError();
          }
        }),
        catchError(err => {
          if (err.message === 'enterpriseConversionError') {
            this.componentInstance.errorMessage = this.SYSTEM_ERROR_MESSAGE;
            this.trackError('enterpriseConversionError', state.isWhitelisted);
          } else if (err.message === 'planDoesntSupportDependents') {
            this.componentInstance.errorMessage = PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE;
            this.trackError('planDoesntSupportDependents', state.isWhitelisted);
          } else if ((err as AlreadyRegisteredError).alreadyRegistered) {
            this.componentInstance.errorMessage = this.EMPLOYEE_ID_ERROR;
            this.trackError('alreadyRegistered', true);
          } else if ((err as EmployeeNotFoundError).employeeNotFound) {
            this.componentInstance.errorMessage = this.EMPLOYEE_ID_ERROR;
            this.trackError('employeeNotFound', state.isWhitelisted);
          } else if ((err as InvalidConversionError).invalidConversion) {
            this.componentInstance.errorMessage = INVALID_CONVERSION_ERROR_MESSAGE;
            this.trackError('enterpriseConversionError', state.isWhitelisted);
          } else {
            this.componentInstance.errorMessage = GENERIC_WHITELISTING_ERROR_MESSAGE;
          }
          return observableThrowError(err);
        }),
      );
    } else {
      return observableThrowError(new Error('Empty ID'));
    }
  }

  private trackSubmission(
    { isWhitelist, fastForwarding }: { isWhitelist: boolean; fastForwarding: boolean },
    module = this.MODULE,
  ) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({ module, isWhitelist, fastForwarding });
  }

  private trackError(error: string, isWhitelist: boolean) {
    this.enterpriseRegistrationAnalyticsService.regInputErrored({
      error,
      formField: 'Employee ID',
      module: this.MODULE,
      isWhitelist,
    });
  }

  private setNextStep(
    state: EnterpriseRegistration,
    whitelistedEmployee: WhitelistedEmployee_whitelistedEmployee,
    captcha: any,
  ): Observable<any> {
    const { b2bCompany } = whitelistedEmployee;

    if (!b2bCompany.includesDependent && whitelistedEmployee.registered) {
      throw new AlreadyRegisteredError();
    }

    if (state.shouldConvertAccount()) {
      return state.submitAccountConversion(captcha, b2bCompany.includesDependent);
    } else {
      if (b2bCompany.includesDependent) {
        state.setCurrentStep(StepName.membershipSelection);
      } else {
        state.setCurrentStep(StepName.accountSetUp);
      }
      return observableOf(true);
    }
  }

  private updateForms(state: EnterpriseRegistration, whitelistedEmployee: WhitelistedEmployee_whitelistedEmployee) {
    if (whitelistedEmployee) {
      const newValues: any = {
        b2bCompanyId: whitelistedEmployee.b2bCompany.id,
        workEmail: whitelistedEmployee.workEmail,
        whitelistedEmployeeId: whitelistedEmployee.employeeId,
      };
      this.form.patchValue(newValues);
      state.whitelistedEmployee = whitelistedEmployee;
      if (!whitelistedEmployee.b2bCompany.includesDependent) {
        state.patchParams({ membershipType: MembershipType.PERSONAL });
      }
    }
  }
}
