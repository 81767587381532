export const FeatureFlags = {
  APPOINTMENT_SEARCH_NEXT_REMOTE_VISIT_MODULE: 'next-remote-visit-module',
  CONSUMER_MARKET_FLEXIBLE_PRICING_TOGGLE: 'consumer-market-flexible-pricing-toggle',
  CONSUMER_PEDIATRIC_REGISTRATION_FREE_TRIAL: 'consumer-pediatric-registration-free-trial',
  CONSUMER_REGISTRATION_APP_SYNC_COMMUNICATION: 'consumer-registration-app-sync-communication',
  CONSUMER_REGISTRATION_APP_SYNC_DEACTIVATE_FLOW: 'consumer-registration-app-sync-deactivate-flow',
  CONSUMER_REGISTRATION_APP_SYNC_GIFTREDEEM_FLOW: 'consumer-registration-app-sync-giftredeem-flow',
  CONSUMER_REGISTRATION_APP_SYNC_PEDIATRIC_FLOW: 'consumer-registration-app-sync-pediatric-flow',
  CONSUMER_REGISTRATION_APPLE_PAY_WEB: 'consumer-registration-apple-pay-web',
  CONSUMER_REGISTRATION_DPH_BANNER_MESSAGE: 'consumer-registration-dph-banner-message',
  CONSUMER_REGISTRATION_DAY_1_BANNER: 'consumer-registration-day-1-banner',
  CONSUMER_REGISTRATION_EXPERIMENTATION_TEST: 'consumer-registration-experimentation-test',
  CONSUMER_REGISTRATION_GIFTING_POLLING_FLOW: 'consumer-registration-gifting-polling-flow',
  CONSUMER_REGISTRATION_INSTALLMENT_PAYMENTS: 'consumer-registration-installment-payments',
  CONSUMER_REGISTRATION_MARKET_BASED_PRICING: 'consumer-registration-market-based-pricing',
  CONSUMER_REGISTRATION_PASSWORDLESS_FIRST_PAGE: 'consumer-registration-passwordless-first-page',
  CONSUMER_REGISTRATION_PROMOTION: 'consumer-registration-promotion',
  CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_ALERT_MODAL: 'consumer-registration-service-area-capacity-alert-modal',
  CONSUMER_REGISTRATION_SERVICE_AREA_ADULT_EXCLUSIONS:
    'consumer-registration-exclude-enterprise-only-service-areas-from-adult-flow',
  CONSUMER_REGISTRATION_UPSELL_VIRTUAL_OFFERING: 'consumer-registration-upsell-virtual-offering',
  CONSUMER_REGISTRATION_QUERY_PARAM: 'consumer-registration-query-param',
  CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_BLOCK: 'consumer-registration-service-area-capacity-block',
  CONSUMER_REGISTRATION_STEP_BASED_BANNER: 'consumer-registration-step-based-banner',
  CONSUMER_REGISTRATION_STRIPE_PAYMENT_INTENTS: 'consumer-registration-stripe-payment-intents',
  CONSUMER_REGISTRATION_VALUE_PROPS_INTERSTITIAL: 'consumer-registration-value-props-interstitial-step-rev-2',
  ENTERPRISE_REGISTRATION_GTM_ANALYTICS: 'enterprise-registration-gtm-analytics',
  ENTERPRISE_ELOQUA_CAMPAIGN_PREFILL: 'enterprise-eloqua-campaign-prefill',
  ENTERPRISE_PERSONAL_DETAILS: 'enterprise-personal-details',
  ENTERPRISE_REGISTRATION_ERROR_STATES: 'enterprise-registration-error-states',
  FAMILY_ACCOUNT_MANAGEMENT_TAB: 'family-account-management-tab',
  GRAPHQL_OFFICES: 'graph-ql-migration-offices',
  GRAPHQL_MIGRATION_DELETE_PATIENT_TIMELINE_POST_COMMENT_DRAFT:
    'graph-ql-migration-delete-patient-timeline-post-comment-draft',
  HOME_SCREEN_REDESIGN: 'home-screen-redesign',
  HOME_SCREEN_LEGACY_RECOMMENDED_SERVICES: 'home-screen-legacy-recommended-services',
  IDENTITY_ACCOUNT_SWITCHING: 'identity-account-switching',
  MEDICAL_RECORDS_DOWNLOAD: 'patient-medical-records-download-request',
  MEMBERSHIP_NEW_STRIPE_WORKFLOW_GIFT_MEMBERSHIP: 'membership-new-stripe-workflow-gift-membership',
  PLAN_SELECTION_ROUTING_PAGE: 'plan-selection-routing-page',
  POST_REGISTRATION_BOOKING: 'post-registration-booking',
  BHX_MEMBER_PROGRAM_EXPERIENCE: 'member-program-experience',
  BHX_PCP_SELECTION_MENTAL_HEALTH_VISIT_EXPERIMENT: 'pcp-selection-mental-health-visit-experiment',
  BHX_MENTAL_HEALTH_TOPIC_ROUTING_FEATURE: 'mental-health-topic-routing-feature',
  BHX_PCP_SELECTION_UI_IMPROVEMENTS_EXPERIMENT: 'pcp-selection-ui-improvements-experiment',
  PATIENT_UI_MAINTENANCE_MODE: 'patient-ui-maintenance-mode',
  PROACTIVE_CARE_ENABLE_CONCIERGE: 'proactive-care-enable-concierge',
  PROACTIVE_CARE_ENABLE_PCP_SELECTION_ONBOARDING_CAROUSEL_CARD:
    'proactive-care-enable-pcp-selection-onboarding-carousel-card',
  REFERRAL_PDF: 'referral-pdf',
  PC_ONBOARDING_PROMPTS_EXPERIMENT: 'homescreen-onboarding-prompts-web',
  PC_GRAPHQL_NOTIFICATION_TARGETS: 'pc-messages-notification-targets-use-graphql',
  PC_FETCH_POSTS_USE_GRAPHQL: 'pc-fetch-posts-use-graphql',
  REGISTRATION_FAMILY_ROUTING_FAQS: 'registration-family-routing-faqs',
  ENTERPRISE_PERSONAL_DETAILS_VERIFICATION: 'enterprise-personal-details-verification',
  VEX_SWITCH_TO_REMOTE: 'switch-to-remote-visit',
  PATIENT_TASK_DETAILS_GRAPHQL: 'patient-task-details-graphql',
  TOPIC_BASED_GUIDANCE: 'topic-based-guidance',
  PC_CONSUMER_VIRTUAL_SERVICE_AREA: 'pc-consumer-virtual-service-area',
  PC_CONSUMER_VIRTUAL_SERVICE_AREA_PEDIATRIC: 'pc-consumer-virtual-service-area-pediatric',
  PC_VIRTUAL_FIRST_HOMESCREEN_WEB: 'pc-virtual-first-homescreen-web',
};

export enum HomeRedesignVariation {
  OFF = 'Off',
}

/**
 * Variants for LaunchDarkly (LD) x MixPanel (MP) workaround.
 */
export const FeatureFlagVariants = {
  /** Indicates the experiment is not running and should tell the client to not send LD parameters to MP*/
  OFF: 'OFF',
  /** Indicates the experiment is running and should tell the client to send LD parameters to MP for members assigned to the control group*/
  ON_CONTROL: 'ON CONTROL',
  /** Indicates the experiment is running and should tell the client to send LD parameters to MP for members assigned to the variant group*/
  ON_VARIANT: 'ON VARIANT',
} as const;
export type FeatureFlagVariants = typeof FeatureFlagVariants[keyof typeof FeatureFlagVariants];

/**
 * Variants for LaunchDarkly (LD) x MixPanel (MP) workaround with `ON` variation
 */
export const FeatureFlagVariantsWithOn = {
  ...FeatureFlagVariants,
  /** Indicates the experiment is running and should tell the client to not send LD parameters to MP.*/
  ON: 'ON',
} as const;
export type FeatureFlagVariantsWithOn = typeof FeatureFlagVariantsWithOn[keyof typeof FeatureFlagVariantsWithOn];
