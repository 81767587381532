import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'om-radio-input-ngrx',
  templateUrl: './radio-input-ngrx.component.html',
  styleUrls: ['./radio-input-ngrx.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputNgrxComponent),
      multi: true,
    },
  ],
})
export class RadioInputNgrxComponent implements ControlValueAccessor {
  // to do: add functions to take input size & create style objects to be
  // be applied on relevant markup elements as inline styles. Possibly add
  // color as well
  // @Input() size: number;
  @Input() ngrxFormControlState: any;
  @Input() label: string;
  @Input() value: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() checked = false;

  /* boilerplate for ControlValueAccessor
   * to allow passing ngrxFormControlState and not anger angular form controls
   * taken from https://www.tsmean.com/articles/angular/angular-control-value-accessor-example/
   * */
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(_input: any) {
    if (!this.ngrxFormControlState) {
      this.onChange(this.value);
    }
  }

  handleSelect() {
    if (!this.disabled) {
      this.writeValue(this.value);
    }
  }
}
