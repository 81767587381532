import { Injectable, Optional, SkipSelf } from '@angular/core';
import { initialize as initializeLDClient, LDClient } from 'launchdarkly-js-client-sdk';
import { LDUser } from 'launchdarkly-js-sdk-common';
import { from, fromEvent, Observable, of as observableOf, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { ConfigService } from '@app/core/config.service';
import { StorageService } from '@app/core/storage.service';

import { TrackMetricProps, UpdateUserProps } from './interfaces';
import { anonymousLDUser, mapPropsToLDUser } from './launch-darkly-user-property-mapper';

const ANONYMOUS_KEY = 'Anonymous';
const ANONYMOUS_UUID_STORAGE_PATH = 'anonymous_ld_uuid_key';

/**
 *  Service wrapper around the LaunchDarkly Javascript SDK.
 * https://docs.launchdarkly.com/sdk/client-side/javascript
 */
@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private ldClient: LDClient;
  onChange$: Observable<any>;
  readonly userIdentified$ = new ReplaySubject<boolean>(1);

  constructor(
    private config: ConfigService,
    private storageService: StorageService,
    @Optional() @SkipSelf() parent?: LaunchDarklyService,
  ) {
    if (parent) {
      throw Error(
        `[LaunchDarklyService]: trying to create multiple instances, but this service should be a singleton.`,
      );
    }

    this.initializeLDClient();
  }

  /**
   * Call when you need percentage rollout or A/B testing for a feature flag targeting anonymous users.
   * Pass in your own random user key or use the UUID generated and returned by this function.
   *
   * WARNING: Please consider the MAU impact before using this method.
   * Usage details: https://app.launchdarkly.com/settings/usage/details/overview
   *
   * @param key
   */
  async setAnonymousUserUUID(key = uuidv4()): Promise<string> {
    const user = this.ldClient.getUser();
    user.key = key;
    user.anonymous = true;
    try {
      await this.identifyUser(user);
      this.storageService.setItem(ANONYMOUS_UUID_STORAGE_PATH, key);
    } catch (e) {
      console.error(e);
    }
    return key;
  }

  isAnonymousUnkeyedUser(): boolean {
    const user = this.ldClient.getUser();
    return user.anonymous && user.key === ANONYMOUS_KEY;
  }

  /**
   * Returns the an observable of the value for a given flag based on the user identified for the LaunchDarkly client instance.
   * @param flag
   * @param defaultValue This value will be returned in the case that the LD client can't be initialized properly
   * @return Observable<any> Expect one of: boolean, number, string, or JSON
   */
  featureFlag$<T>(flag: string, defaultValue?: T): Observable<T> {
    return from(this.ldClient.waitForInitialization()).pipe(
      map(() => this.ldClient.variation(flag, defaultValue)),
      catchError(_yoo => {
        const errorPayload = 'Unable to initialize the LaunchDarkly SDK. Flagged features will be disabled';
        console.error(errorPayload);
        return observableOf(defaultValue);
      }),
    );
  }

  /**
   * Takes User and maps various properties to the LDUser interface then updates LaunchDarkly with the user property data
   * @param user
   * @param customAttributes
   * @param privateAttributes
   */
  async updateUser(props: UpdateUserProps): Promise<void> {
    const currentUser = this.ldClient.getUser();
    const newUser = mapPropsToLDUser(props);
    if (![currentUser.key, newUser.key].includes(ANONYMOUS_KEY) && currentUser.key !== newUser.key) {
      this.ldClient.alias(newUser, currentUser);
    }
    await this.identifyUser(newUser).then(
      () => {
        this.userIdentified$.next(true);
      },
      () => {
        this.userIdentified$.next(false);
      },
    );
  }

  /**
   * Should be used to only update custom attributes on for anonymous user.
   * @param customAttributes
   */
  async updateAnonUserCustomAttributes(customAttributes: Record<string, any>): Promise<void> {
    const user = this.ldClient.getUser(); // Get the current user
    user.custom = { ...user.custom, ...customAttributes };
    await this.identifyUser(user);
  }

  async resetUser(): Promise<void> {
    const newAnonymousUser = this.anonymousLDUserWithStoredKey();
    await this.identifyUser(newAnonymousUser);
  }

  /**
   * Track custom events that map to Metrics configured in LaunchDarkly which are typically linked to one or more
   * feature flags for experimentation
   * @param props TrackMetricProps
   */
  track(props: TrackMetricProps) {
    this.ldClient.track(props.key, props.data, props.metricValue);
  }

  private initializeLDClient() {
    const initUser = this.anonymousLDUserWithStoredKey();
    this.ldClient = initializeLDClient(this.config.json.launchDarklyClientId, initUser, {
      sendEventsOnlyForVariation: true,
    });
    if ((<any>window).Cypress) {
      // Dont sign up for the EventSource stream of feature flag changes if this is a cypress test
      // As of yet we haven't figured out how to stub these EventSource streams, so we're just disabling it for cypress for now
    } else {
      this.onChange$ = fromEvent(this.ldClient, 'change');
    }
  }

  private async identifyUser(user: LDUser): Promise<void> {
    await this.ldClient.identify(user);
  }

  private anonymousLDUserWithStoredKey(): LDUser {
    const anonymousUser = anonymousLDUser();
    const storedKey = this.storageService.getItem(ANONYMOUS_UUID_STORAGE_PATH);
    if (storedKey) {
      anonymousUser.key = storedKey;
    }
    return anonymousUser;
  }
}
