import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MODULE_WORK_EMAIL_VALIDATION_PAGE } from '@app/core/mixpanel.constants';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { emailValidator } from '@app/shared/email-validator';
import { OfficeService } from '@app/shared/office.service';

import { LinksService } from '../../../core/links.service';
import { B2bCompany, B2bCompany_b2bCompany } from '../__generated__/B2bCompany';
import { WhitelistedEmployee, WhitelistedEmployee_whitelistedEmployee } from '../__generated__/WhitelistedEmployee';
import { B2bCompanyGraphQL } from '../b2b-company-graphql.service';
import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { MembershipType } from '../membership-type';
import { RegistrationStep } from '../registration-step';
import {
  AlreadyRegisteredError,
  EMAIL_NOT_FOUND_ERROR_MESSAGE,
  GENERIC_WHITELISTING_ERROR_MESSAGE,
  INVALID_CONVERSION_ERROR_MESSAGE,
  InvalidConversionError,
  PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE,
  WorkEmailNotFoundError,
} from '../whitelisted-employee-errors';
import { WhitelistedEmployeeGraphQL } from '../whitelisted-employee-graphql.service';
import { WorkEmailStepComponent } from './work-email-step.component';

@Injectable()
export class WorkEmailConfig extends RegistrationStep {
  ALREADY_REGISTERED_MESSAGE = `This email is invalid or already in use. If you’ve lost access to your account, <a href="${this.links.forgotPassword}">reset your password</a>, or email <a href="${this.links.adminEmail}">admin@onemedical.com</a>.`;

  MODULE = MODULE_WORK_EMAIL_VALIDATION_PAGE;
  SYSTEM_ERROR_MESSAGE = 'There was an error submitting your work email. Please try again later.';

  captchaNeeded = true;
  component = WorkEmailStepComponent;
  componentInstance: WorkEmailStepComponent;
  progress = 0;
  form: FormGroup = this.formBuilder.group({
    b2bCompanyId: '',
    whitelistedEmployeeId: '',
    workEmail: ['', [Validators.required, emailValidator]],
  });

  constructor(
    private b2bCompanyGraphQL: B2bCompanyGraphQL,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private readonly links: LinksService,
    private formBuilder: FormBuilder,
    private whitelistedEmployeeGraphQL: WhitelistedEmployeeGraphQL,
    private officeService: OfficeService,
  ) {
    super();
  }

  canGoBack(state: EnterpriseRegistration): boolean {
    return !!state.getPreviousStep();
  }

  initComponent(component: WorkEmailStepComponent, enterpriseRegistration: EnterpriseRegistration) {
    this.componentInstance = component;
    component.form = this.form;
    this.officeService.getRoundedOfficeCount().subscribe(count => {
      component.numberOfOffices = count;
      component.loading = false;
    });
    this.componentInstance.emailErrorModalViewed.subscribe(() => {
      this.enterpriseRegistrationAnalyticsService.emailErrorModalViewed({
        isWhitelist: enterpriseRegistration.isWhitelisted,
        serviceArea: enterpriseRegistration.serviceArea,
      });
    });
    this.componentInstance.modalClosed.subscribe(() => {
      this.enterpriseRegistrationAnalyticsService.emailErrorModalClosed({
        isWhitelist: enterpriseRegistration.isWhitelisted,
        serviceArea: enterpriseRegistration.serviceArea,
      });
    });
  }

  patchParams(params: Record<string, any>) {
    const { b2bCompanyId, email } = params;
    if (email && this.form.value.workEmail === '') {
      this.form.patchValue({ workEmail: email });
    }
    if (b2bCompanyId) {
      this.form.patchValue({ b2bCompanyId });
    }
  }

  submit(state: EnterpriseRegistration, captcha: any): Observable<any> {
    const { form } = this;
    const { workEmail } = form.value;
    if (this.componentInstance?.goToActivationCode) {
      this.enterpriseRegistrationAnalyticsService.enterActivationCodeClicked({
        isWhitelist: state.isWhitelisted,
        serviceArea: state.serviceArea,
      });
      this.componentInstance.modalService.dismissAll();
      state.setCurrentStep(StepName.activationCode);
      return observableOf(true);
    }
    if (form.valid) {
      state.details.workEmail = workEmail;
      const apiCalls = captcha.getToken().pipe(
        switchMap((reCaptchaToken: string) => this.whitelistedEmployeeGraphQL.fetch({ workEmail, reCaptchaToken })),
        switchMap((whitelistingGraphQL: any) =>
          captcha.getToken().pipe(
            switchMap((reCaptchaToken: string) =>
              this.b2bCompanyGraphQL.fetch({ b2bEmail: workEmail, reCaptchaToken }),
            ),
            map((b2bCompanyGraphQL: any) => ({ whitelistingGraphQL, b2bCompanyGraphQL })),
          ),
        ),
      );
      return apiCalls.pipe(
        switchMap((response: any) => {
          const { b2bCompany } = (response.b2bCompanyGraphQL as ApolloQueryResult<B2bCompany>).data;
          const { whitelistedEmployee } = (response.whitelistingGraphQL as ApolloQueryResult<WhitelistedEmployee>).data;
          state.b2bCompany = (whitelistedEmployee && whitelistedEmployee.b2bCompany) || b2bCompany;
          state.details.b2bCompanyId = state.b2bCompany && +state.b2bCompany.id;
          if (whitelistedEmployee) {
            state.details.activationCode = null; // clear in case they've entered one then gone back in the flow
            state.details.whitelistedEmployeeId = +whitelistedEmployee.id;
          }
          this.updateForm(state, whitelistedEmployee);
          const { isWhitelisted, fastForwarding } = state;
          this.trackSubmission({ isWhitelisted, workEmail, fastForwarding }, this.MODULE);
          return this.setNextStep(state, whitelistedEmployee, b2bCompany, captcha);
        }),
        catchError(err => {
          if (err.message === 'enterpriseConversionError') {
            this.componentInstance.errorMessage = this.SYSTEM_ERROR_MESSAGE;
            this.trackError(state.isWhitelisted, 'enterpriseConversionError');
          } else if (err.message === 'planDoesntSupportDependents') {
            this.componentInstance.errorMessage = PLAN_DOESNT_SUPPORT_DEPENDENTS_ERROR_MESSAGE;
            this.trackError(state.isWhitelisted, 'planDoesntSupportDependents');
          } else if ((err as AlreadyRegisteredError).alreadyRegistered) {
            this.trackError(state.isWhitelisted, 'alreadyRegistered', this.MODULE);
            this.componentInstance.errorMessage = this.ALREADY_REGISTERED_MESSAGE;
          } else if ((err as InvalidConversionError).invalidConversion) {
            this.trackError(state.isWhitelisted, 'enterpriseConversionError');
            this.componentInstance.errorMessage = INVALID_CONVERSION_ERROR_MESSAGE;
          } else if ((err as WorkEmailNotFoundError).workEmailNotFound) {
            this.trackError(state.isWhitelisted, 'workEmailNotFoundError');
            this.componentInstance.errorMessage = EMAIL_NOT_FOUND_ERROR_MESSAGE;
            this.componentInstance.customModalErrorMessage = (err as WorkEmailNotFoundError).message;
          } else {
            this.componentInstance.errorMessage = GENERIC_WHITELISTING_ERROR_MESSAGE;
          }
          return observableThrowError(err);
        }),
      );
    } else {
      this.trackError(state.isWhitelisted, 'invalidEmail', this.MODULE);
      return observableThrowError(new Error('Invalid Email'));
    }
  }

  private trackSubmission(
    {
      isWhitelisted,
      workEmail,
      fastForwarding,
    }: { isWhitelisted: boolean; workEmail: string; fastForwarding: boolean },
    module = this.MODULE,
  ) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      module,
      isWhitelist: isWhitelisted,
      workEmailAddress: workEmail,
      fastForwarding,
    });
    this.enterpriseRegistrationAnalyticsService.trackWithLaunchDarkly({ key: 'Enterprise Registration Begin' });
  }

  private trackError(isWhitelisted: boolean, message: string, module = this.MODULE) {
    this.enterpriseRegistrationAnalyticsService.regInputErrored({
      error: message,
      formField: 'Work Email Validation',
      isWhitelist: isWhitelisted,
      module,
    });
  }

  private updateForm(state: EnterpriseRegistration, whitelistedEmployee: WhitelistedEmployee_whitelistedEmployee) {
    if (!whitelistedEmployee) {
      return;
    }
    state.whitelistedEmployee = whitelistedEmployee;

    const newValues: any = {
      b2bCompanyId: whitelistedEmployee.b2bCompany.id,
      whitelistedEmployeeId: whitelistedEmployee.id,
    };
    this.form.patchValue(newValues);
    if (!whitelistedEmployee.b2bCompany.includesDependent) {
      state.details.membershipType = MembershipType.PERSONAL;
      state.patchParams({ membershipType: MembershipType.PERSONAL });
    }
  }

  private setNextStep(
    state: EnterpriseRegistration,
    whitelistedEmployee: WhitelistedEmployee_whitelistedEmployee,
    b2bCompany: B2bCompany_b2bCompany,
    captcha: any,
  ) {
    if (!whitelistedEmployee) {
      if (b2bCompany?.displayElistError) {
        throw new WorkEmailNotFoundError(b2bCompany.customElistError || '');
      }
      state.setCurrentStep(StepName.activationCode);
      return observableOf(true);
    }

    const employeeB2bCompany = whitelistedEmployee.b2bCompany;

    // Whitelisted employees that have a different workEmail and employeeId are part of an organization
    // that requires them to enter their employee id, redirect them to that page.
    if (whitelistedEmployee.employeeId !== whitelistedEmployee.workEmail) {
      state.patchParams({ b2b_company_id: employeeB2bCompany.id });
      state.setCurrentStep(StepName.employeeId);
      return observableOf(true);
    }

    if (!employeeB2bCompany.includesDependent && whitelistedEmployee.registered) {
      throw new AlreadyRegisteredError();
    }

    if (state.shouldConvertAccount()) {
      return state.submitAccountConversion(captcha, employeeB2bCompany.includesDependent);
    } else {
      if (employeeB2bCompany.includesDependent) {
        state.setCurrentStep(StepName.membershipSelection);
      } else {
        state.setCurrentStep(StepName.accountSetUp);
      }

      return observableOf(true);
    }
  }
}
