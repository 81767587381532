import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { addYears } from 'date-fns';
import { zip as zipObservables } from 'rxjs';

import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { formatDate } from '@app/shared';

import { CreateHealthInformationReleaseAuthorizationInput } from '../../../../__generated__/globalTypes';
import { CreateHealthInformationReleaseAuthorizationGraphqlService } from './create-health-information-release-authorization-graphql.service';

@Injectable()
export class HealthInformationReleaseService {
  DOCUMENT_VERSION = '1.3';

  constructor(
    private createAuthorizationGraphQL: CreateHealthInformationReleaseAuthorizationGraphqlService,
    private membershipService: MembershipService,
    private userService: UserService,
  ) {}

  getUserAndMembership() {
    return zipObservables(this.userService.getUser(), this.membershipService.getMembership());
  }

  createAuthorization(form: FormGroup) {
    return this.createAuthorizationGraphQL.mutate({ input: this.authorizationParams(form) });
  }

  private authorizationParams(form: FormGroup): CreateHealthInformationReleaseAuthorizationInput {
    const { recipientTelephone, signatureText, signedAt } = form.value;
    return {
      recipientTelephone,
      signatureText,
      signedAt: signedAt.date,
      expiresAt: formatDate(addYears(new Date(), 2), 'yyyy-MM-dd'),
      documentVersion: this.DOCUMENT_VERSION,
    };
  }
}
