import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import {
  passwordComplexityErrorsToMessages,
  passwordComplexityValidator,
} from '@app/shared/password-complexity-validator.directive';

@Component({
  selector: 'om-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss', '../form-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },

    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(private formBuilder: FormBuilder) {}

  get password(): AbstractControl {
    return this.passwordForm.controls.password;
  }

  errorMessage: string;
  showPassword = false;
  passwordForm: FormGroup;
  inputFocused = false;
  inputDirty = false;
  passwordComplexityErrorsToMessages = passwordComplexityErrorsToMessages;
  serverError: string;

  @Input() submitting: boolean;

  onTouched: () => void = () => {};

  hasErrors(errorKey: string): boolean {
    return !!(this.password.invalid && this.password.errors[errorKey]);
  }

  noError(errorKey: string): boolean {
    return !!(this.password.valid || !this.password.errors[errorKey]);
  }

  ngOnInit() {
    this.createForm();
  }

  registerOnChange(fn: any): void {
    this.passwordForm.valueChanges.subscribe(data => {
      fn(data.password);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    if (val) {
      this.passwordForm.setValue({ password: val }, { emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.errors?.serverError) {
      this.passwordForm.controls.password.setErrors({
        ...this.passwordForm.controls.password.errors,
        serverError: control.errors.serverError,
      });
    }
    this.setErrorMessages();
    return this.passwordForm.valid
      ? null
      : { password: { valid: false, errors: this.passwordForm.controls.password.errors } };
  }

  private createForm() {
    this.passwordForm = this.formBuilder.group({
      password: ['', { disabled: this.submitting, validators: [passwordComplexityValidator] }],
    });
  }

  focusContainingDiv() {
    this.inputFocused = true;
  }

  blurContainingDiv() {
    this.inputFocused = false;
    this.inputDirty = true;
  }

  markAsTouchedAndDirty() {
    this.password.markAsDirty();
    this.password.markAsTouched();
    this.inputDirty = true;
  }

  private setErrorMessages() {
    this.serverError = this.password.errors?.serverError
      ? this.buildErrorMessage(this.password.errors.serverError.messages)
      : null;
  }

  private buildErrorMessage(messages: string[]) {
    let message = '';
    if (messages.length > 1) {
      const lastError = messages.pop();
      message = `Your password ${messages.join(', ')} and ${lastError}`;
    } else if (messages) {
      message = `Your password ${messages[0]}`;
    }

    return message;
  }
}
