<ng-template #cardContent>
  <div class="position-relative h-100" [class.sizing-compact]="sizing === StandardCardSizing.Compact">
    <div
      class="card omgui-card d-flex h-100 justify-content-start p-3"
      [ngClass]="{
        'flex-row flex-sm-column flex-lg-row align-items-center align-items-sm-start align-items-lg-center':
          fullWidthAlignment === StandardCardFullWidthAlignment.Horizontal,
        'flex-column align-items-start': fullWidthAlignment === StandardCardFullWidthAlignment.Vertical,
        'p-md-4': sizing === StandardCardSizing.Responsive
      }"
    >
      <div
        *ngIf="icon"
        class="icon mr-3"
        [ngClass]="{
          'mb-sm-2 mb-lg-0': fullWidthAlignment === StandardCardFullWidthAlignment.Horizontal,
          'mb-2': fullWidthAlignment === StandardCardFullWidthAlignment.Vertical,
          'mr-lg-3': sizing === StandardCardSizing.Responsive
        }"
      >
        <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
      </div>

      <div>
        <h4 class="label h5 m-0" [id]="uniqueLabel">{{ label }}</h4>
        <div class="sublabel text-emphasis-medium" data-cy="omgui-standard-card-sublabel-content">
          <!-- Allow only sublabel or secondaryLabel but not both -->
          <ng-container *ngIf="sublabel; else secondaryLabelContent">{{ sublabel }}</ng-container>
          <ng-template #secondaryLabelContent>
            <ng-container *ngTemplateOutlet="secondaryLabel?.templateRef"></ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf="footer" class="position-absolute w-100 text-center mx-auto my-2 d-none d-sm-block text-emphasis-medium">
      <ng-container *ngTemplateOutlet="footer.templateRef"></ng-container>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!route && !href">
  <div
    (click)="onClick($event)"
    (keydown.enter)="onClick($event)"
    role="button"
    tabindex="0"
    class="omgui-card-link-wrapper h-100"
    [attr.aria-labelledby]="uniqueLabel"
    [attr.data-cy]="dataCy"
  >
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="route">
  <a
    class="text-decoration-none omgui-card-link-wrapper h-100"
    [attr.aria-labelledby]="uniqueLabel"
    [routerLink]="route"
    [omTrackLink]="trackLink"
    [queryParams]="queryParams"
    [attr.data-cy]="dataCy"
  >
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="hrefWithQueryParams">
  <a
    class="text-decoration-none omgui-card-link-wrapper h-100"
    [attr.aria-labelledby]="uniqueLabel"
    [href]="hrefWithQueryParams"
    target="_blank"
    [omTrackLink]="trackLink"
  >
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-container>
