import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

type UpdateOnInput = 'blur' | 'change' | 'submit';

import { emailValidator } from '@app/shared/email-validator';

@Component({
  selector: 'om-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['../form-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },

    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true,
    },
  ],
})
export class EmailInputComponent implements AfterViewInit, OnInit, ControlValueAccessor, Validator {
  @Input() autofocus = false;
  @Input() placeholder = 'Email';
  @Input() updateOn = 'blur' as UpdateOnInput;
  @ViewChild('input', { static: true }) input: ElementRef;

  emailForm: FormGroup;
  invalidEmail: boolean;
  serverError: string;

  constructor(public formBuilder: FormBuilder) {}

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focus();
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.emailForm = this.formBuilder.group({
      email: this.formBuilder.control('', {
        validators: [Validators.required, emailValidator],
        updateOn: this.updateOn,
      }),
    });
  }

  onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.emailForm.setValue({ email: val }, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.emailForm.valueChanges.subscribe(formData => {
      fn(formData.email);
    });
  }

  private setErrorMessages() {
    this.serverError =
      this.email.errors && this.email.errors.serverError
        ? this.setupErrorMessage(this.email.errors.serverError.messages)
        : null;
    this.invalidEmail = !!(this.emailForm.dirty && this.email.errors && this.email.errors.invalidEmail);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (c.errors && c.errors.serverError) {
      this.emailForm.controls.email.setErrors({
        ...this.emailForm.controls.email.errors,
        serverError: c.errors.serverError,
      });
    }
    this.setErrorMessages();
    return this.emailForm.valid ? null : { email: { valid: false, errors: this.emailForm.controls.email.errors } };
  }

  get email() {
    return this.emailForm.controls.email;
  }

  setupErrorMessage(messages: string[]) {
    let message = '';
    if (messages.length > 1) {
      const lastError = messages.pop();
      message = `This email ${messages.join(', ')} and ${lastError}`;
    } else if (messages) {
      message = `This email ${messages[0]}`;
    }

    return message;
  }

  markAsDirty() {
    this.emailForm.controls.email.markAsDirty();
  }

  markAsTouched() {
    this.emailForm.controls.email.markAsTouched();
  }

  markAsTouchedAndDirty() {
    this.emailForm.controls.email.markAsTouched();
    this.emailForm.controls.email.markAsDirty();
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  blur(): void {
    this.input.nativeElement.blur();
  }
}
