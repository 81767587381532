<div *ngFor="let answerGroup of patientAnswerGroups; index as i">
  <h3>Eligible Item or Service</h3>
  <label for="name-{{ i }}" class="mt-2">Name</label>
  <span class="text-danger">*</span>
  <om-expanding-text-box
    [value]="answerGroup.name"
    [lined]="true"
    placeholder="e.g. Accupressure, Fish Oil, Yoga"
    (valueChange)="updateTextValue($event, i, 'name')"
  >
  </om-expanding-text-box>
  <div class="text-danger mb-4" *ngIf="answerGroup.name.length > maxAnswerLength">
    Text answers are limited to {{ maxAnswerLength }} characters
  </div>
  <label for="dosage-{{ i }}">Dosage (if applicable)</label>
  <om-expanding-text-box
    [value]="answerGroup.dosage"
    [lined]="true"
    placeholder="e.g. 10 mg capsule"
    (valueChange)="updateTextValue($event, i, 'dosage')"
  >
  </om-expanding-text-box>
  <div class="text-danger mb-4" *ngIf="answerGroup.dosage.length > maxAnswerLength">
    Text answers are limited to {{ maxAnswerLength }} characters
  </div>
  <label for="dosage-{{ i }}">Yearly Quantity (if applicable)</label>
  <om-expanding-text-box
    [value]="answerGroup.quantity"
    [lined]="true"
    placeholder="e.g. 1"
    (valueChange)="updateTextValue($event, i, 'quantity')"
  >
  </om-expanding-text-box>
  <div class="text-danger mb-4" *ngIf="answerGroup.quantity.length > maxAnswerLength">
    Text answers are limited to {{ maxAnswerLength }} characters
  </div>
  <label for="reason-{{ i }}">Medical Reason</label>
  <span class="text-danger">*</span>
  <om-expanding-text-box
    [value]="answerGroup.reason"
    [lined]="true"
    placeholder="e.g. Sprained Ankle"
    (valueChange)="updateTextValue($event, i, 'reason')"
  >
  </om-expanding-text-box>
  <div class="text-danger mb-4" *ngIf="answerGroup.reason.length > maxAnswerLength">
    Text answers are limited to {{ maxAnswerLength }} characters
  </div>
  <button
    *ngIf="patientAnswerGroups.length > 1"
    type="button"
    (click)="removeAnswerGroup(i)"
    class="mb-4 mt-n4"
    [attr.data-cy]="'delete-group-' + i"
  >
    - Delete
  </button>
</div>
<button type="button" (click)="addAnswerGroup()">+ Add another</button>
