<div class="invite-friends">
  <div class="modal-header border-0">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-content px-5 pb-5 text-center border-0">
    <ng-container *ngIf="this.showThankYou; then thankYouView; else formView"></ng-container>

    <ng-template #formView>
      <ng-container *ngIf="this.referralDiscount; then incentivizedCallout; else altruisticCallout"></ng-container>

      <ng-template #incentivizedCallout>
        <h2>Give {{ this.referralDiscount }} off</h2>
        <div class="modal-subheader mb-4">
          Be the one to introduce your friends to better healthcare. Give a {{ this.referralDiscount }} discount on
          membership.
        </div>
      </ng-template>

      <ng-template #altruisticCallout>
        <h2>Invite friends</h2>
        <div class="modal-subheader mb-4">Be the one to introduce your friends to better healthcare.</div>
      </ng-template>

      <form class="form-inline" novalidate>
        <div class="input-group">
          <input
            ngbAutofocus
            type="email"
            class="form-control"
            placeholder="Enter email"
            data-cy="invite-email"
            [readonly]="this.invitePending"
            #emailInput
          />
          <span class="input-group-btn">
            <button
              class="btn btn-primary ml-2 px-4"
              [class.pending]="this.invitePending"
              type="submit"
              data-cy="invite-friends-submit"
              (click)="validateAndSubmit($event, emailInput)"
            >
              <span *ngIf="!this.invitePending">Invite</span>
              <om-logo-spinner class="spinner" *ngIf="this.invitePending" size="25"></om-logo-spinner>
            </button>
          </span>
          <div class="invalid-feedback">Please enter a valid email address.</div>
        </div>
      </form>

      <om-separator text="or"></om-separator>

      <div class="referral-link-section d-flex align-items-center p-3">
        <input class="referral-link-readonly" readonly value="{{ this.referralLink }}" />
        <div
          role="button"
          tabindex="0"
          class="d-inline-block js-referral-link-copy-button referral-link-copy-button ml-3"
          #copyLinkButton
          (keydown.enter)="copyLink(copyLinkButton)"
          (keydown.space)="copyLink(copyLinkButton)"
        >
          <om-svg-copy-icon class="copy-icon" (click)="copyLink(copyLinkButton)"></om-svg-copy-icon>
          <om-svg image="checkmark" class="checkmark"></om-svg>
          <!-- todo: refactor to use <om-svg-checkmark> component -->
        </div>
      </div>
    </ng-template>

    <ng-template #thankYouView>
      <om-svg-phone-in-green-circle></om-svg-phone-in-green-circle>
      <h1>Invite sent!</h1>
      <div class="modal-subheader mb-4">Thanks for sharing One Medical.</div>
      <div><button class="btn btn-outline-primary btn-lg" (click)="inviteMore()">Invite More Friends</button></div>
    </ng-template>
  </div>
</div>
