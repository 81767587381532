import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE } from '@app/core/mixpanel.constants';
import { MembershipType } from '@app/registration/enterprise/membership-type';
import { StepName } from '@app/registration/enterprise/registration-step-name';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import { SuccessStepComponent } from './success-step.component';

@Injectable()
export class SuccessConfig extends RegistrationStep implements OnDestroy {
  private readonly selectedStep$ = new ReplaySubject<StepName>(1);
  private readonly destroy$ = new Subject<void>();

  readonly GA_LABEL = 'Congratulations_Step';
  readonly MODULE = MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE;
  readonly CONSENT_FORM_REDIRECT_B2B_COMPANIES = [753];
  readonly component = SuccessStepComponent;
  readonly progress = 90;
  readonly form: FormGroup;

  constructor(private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService) {
    super();
  }

  canGoBack(): boolean {
    return false;
  }

  initComponent(component: SuccessStepComponent, state: EnterpriseRegistration) {
    component.patientEmail = state.patient.email;
    component.patientFirstName = state.patient.firstName;
    component.module = this.MODULE;
    component.isNewPatient = !state.enterpriseConversionComplete;
    component.isWhitelisted = state.isWhitelisted;
    component.shouldRedirectToConsentForm = this.shouldRedirectToConsentForm(state.b2bCompanyId);
    if (state.b2bCompany) {
      component.showDirectSignup = state.b2bCompany.includesDependent;
    }
    if (state.patient.enterpriseRegistrationDetails.membershipType !== MembershipType.PERSONAL) {
      component.showDirectSignup = false;
    }
    component.isOMNow = state.patient.serviceArea.virtual;
    component.nextStepSelected.asObservable().pipe(takeUntil(this.destroy$)).subscribe(this.selectedStep$);
  }

  submit(state: EnterpriseRegistration): Observable<boolean> {
    return this.selectedStep$.pipe(
      tap(stepName => {
        state.setCurrentStep(stepName);
      }),
      map(() => true),
    );
  }

  trackPageVisit(
    enterpriseRegistration: EnterpriseRegistration,
    enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
  ) {
    enterpriseRegistrationAnalyticsService.pageViewed({
      module: this.MODULE,
      isWhitelist: enterpriseRegistration.isWhitelisted,
      source: enterpriseRegistration.successPageSource,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private shouldRedirectToConsentForm(b2bCompanyId: number) {
    // TODO: Should refactor this to remove hardcoded company ID
    return this.CONSENT_FORM_REDIRECT_B2B_COMPANIES.includes(b2bCompanyId);
  }
}
