import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import forEach from 'lodash-es/forEach';
import mapKeys from 'lodash-es/mapKeys';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export type ParamValueMap = Record<string, any>;

export interface ControlDescription {
  name: string;
  value: any;
  disabled?: boolean;
}

export class InlineControl {
  invalid: boolean;

  constructor(public formControl: AbstractControl) {
    this.invalid = false;
  }
}

export class InlineForm {
  controls: Record<string, InlineControl> = {};
  formGroup: FormGroup = null;
  canDelete = false;
  showingForm = false;
  submitting = false;
  name: string;
  controlNameToApiParamName: ParamValueMap;
  initForm: () => void;
  showingFormEmitter: EventEmitter<boolean>;

  private destroy$ = new Subject();

  constructor(config: {
    name: string;
    controlNameToApiParamName?: ParamValueMap;
    initForm: () => void;
    showingFormEmitter: EventEmitter<boolean>;
    canDelete?: boolean;
  }) {
    this.name = config.name;
    this.controlNameToApiParamName = config.controlNameToApiParamName || {};
    this.initForm = config.initForm;
    this.showingFormEmitter = config.showingFormEmitter;
    this.canDelete = config.canDelete;
  }

  /**
   * @deprecated use FormBuilder instead. Factory method group() does the same thing, and
   * InlineForm.controls doesn't appear particularly useful. One can access the controls using
   * InlineForm.formGroup.controls or formGroup.get().
   *
   * More info: https://angular.io/guide/reactive-forms
   */
  initFormByDescriptions(controlDescriptions: ControlDescription[]) {
    const controls = controlDescriptions
      .map(controlDescription => {
        const control = new FormControl({ value: controlDescription.value, disabled: !!controlDescription.disabled });
        this.controls[controlDescription.name] = new InlineControl(control);
        return { [controlDescription.name]: control };
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});

    this.formGroup = new FormGroup(controls);
  }

  showForm(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }

    this.initForm();

    forEach(this.controls, (control: InlineControl) => {
      control.formControl.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(_ => {
        control.invalid = control.formControl.dirty && control.formControl.invalid;
      });
    });

    this.showingForm = true;
    this.showingFormEmitter.emit(true);
  }

  hideForm(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }

    this.unsubscribe();

    this.showingForm = false;
    this.showingFormEmitter.emit(false);
  }

  unsubscribe() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applySubmitSuccess() {
    this.hideForm();
    this.submitting = false;
  }

  applySubmitError(errorMessage: string) {
    this.formGroup.setErrors({ apiError: errorMessage });
    this.submitting = false;
  }
}

@Component({
  selector: 'om-inline-form',
  templateUrl: './inline-form.component.html',
  styleUrls: ['./inline-form.component.scss'],
})
export class InlineFormComponent implements OnDestroy {
  @Input() inlineForm: InlineForm;
  @Input() formGroup: FormGroup;
  @Output() formSubmit = new EventEmitter<ParamValueMap>();
  @Output() formDelete = new EventEmitter<ParamValueMap>();
  @Output() formCancel = new EventEmitter<ParamValueMap>();

  ngOnDestroy() {
    this.inlineForm.unsubscribe();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.inlineForm.submitting = true;

    const params = mapKeys(this.formGroup.value, (value, key) => this.inlineForm.controlNameToApiParamName[key] || key);

    this.formSubmit.emit(params);
  }

  onDelete() {
    if (this.formGroup.invalid) {
      return;
    }

    this.inlineForm.submitting = true;

    this.formDelete.emit();
  }

  onCancel() {
    this.formCancel.emit();
  }
}
