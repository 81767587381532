<div class="card">
  <div class="card-body">
    <div class="text-center">
      <om-logo class="om-logo"></om-logo>
      <p class="mb-3">{{ signInText }}</p>
    </div>

    <form #loginForm="ngForm">
      <div class="form-group mb-3" [class.has-error]="!userNameInput.valid && userNameInput.touched">
        <input
          type="email"
          name="username"
          class="form-control lined-input"
          required
          [(ngModel)]="username"
          #userNameInput="ngModel"
          (keypress)="handleKeyPress($event)"
          placeholder="Email"
        />
      </div>
      <div class="form-group mb-1" [class.has-error]="!passwordInput.valid && passwordInput.touched">
        <input
          type="password"
          name="password"
          class="form-control lined-input"
          required
          [(ngModel)]="password"
          #passwordElement
          #passwordInput="ngModel"
          (keypress)="handleKeyPress($event)"
          placeholder="Password"
        />
      </div>
      <div class="forgot-password small text-right mb-3">
        <a [href]="links.forgotPassword">Forgot Password?</a>
      </div>

      <button
        type="button"
        class="btn btn-lg btn-primary btn-block mt-4"
        (click)="login()"
        [disabled]="processing"
        name="submit"
      >
        <span [hidden]="processing">Log In</span> <span [hidden]="!processing">Logging In...</span>
      </button>
      <div [hidden]="formError.length == 0" class="text-danger">{{ formError }}</div>
    </form>
  </div>
</div>

<div class="card-body">
  <div class="text-center">
    <p class="mb-3" data-cy="consumer-registration-link">
      Not a One Medical member? <a [href]="links.registrationConsumer">Join now</a>
    </p>
    <p class="mb-3">
      Have a sponsored benefit code?
      <a data-cy="enterprise-registration-link" routerLink="/registration/enterprise">Enter code</a>
    </p>
  </div>
</div>
