<div class="forgot-password-container container-fluid h-100">
  <div class="row">
    <div class="col-12">
      <div class="header row text-center">
        <div class="col">
          <a [href]="links.home">
            <om-logo class="mx-auto mt-5"></om-logo>
          </a>
          <div class="mt-4" *ngIf="showErrorMsg" data-cy="error-banner">
            <omgui-banner [type]="BannerType.ErrorLight">{{ genericErrorMessage }}</omgui-banner>
          </div>

          <div class="mt-4" *ngIf="showSuccessMsg" data-cy="success-banner">
            <omgui-banner [type]="BannerType.SuccessLight">
              <ng-container *ngTemplateOutlet="legacyResetInstructionsSentBannerContent"></ng-container>
            </omgui-banner>

            <ng-template #legacyResetInstructionsSentBannerContent>
              <strong>Reset password instructions sent.</strong>
              <br class="d-sm-none" />
              If an account is associated with the email you enter, you'll receive instructions to reset your password.
            </ng-template>
          </div>
          <h1 class="mt-5">Forgot password</h1>
          <p>Enter the email address associated with your account.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
      <div class="form row my-3">
        <div class="col">
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
            <label for="email" class="small mb-0">Email</label>
            <input
              [class.is-invalid]="emailControl.invalid && isInvalidFormSubmitted"
              type="email"
              name="email"
              class="form-control"
              required
              formControlName="email"
              placeholder="Email"
            />
            <div *ngIf="emailControl.invalid && isInvalidFormSubmitted" data-cy="email-errors">
              <small *ngIf="emailControl.errors?.required" class="text-danger">Email is required</small>
              <small *ngIf="emailControl.errors?.email" class="text-danger">Please provide a valid email address</small>
            </div>

            <omgui-checkbox
              class="d-block mt-3 mb-4"
              label="Send the link to my preferred email address instead"
              formControlName="preferredEmail"
            ></omgui-checkbox>
            <button type="submit" class="btn btn-lg btn-primary btn-block mt-4" [disabled]="processing" name="submit">
              <span [hidden]="processing">Reset password</span> <span [hidden]="!processing">Processing...</span>
            </button>
          </form>
        </div>
      </div>
      <div class="actions row my-5 text-center">
        <div class="col">
          <p class="mb-3">
            Remember your password?
            <a [href]="links.login">Log in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
